<template>
  <loading-screen v-if="is_loading_blind || is_loading_fabrics || is_loading_articles"></loading-screen>
  <div v-else class="page-container">
    <div v-if="blind_details" class="header-container">
      <div class="title-container">
        <router-link :to="{name: 'roller-blind-choice'}">
          <font-awesome-icon :icon="['far', 'arrow-circle-left']"/>
        </router-link>
        <h1>{{ blind_details.attributes.name }}</h1>
      </div>
      <div v-if="!$route.query.token && !$route.query.quote && !$route.query.cart_order" class="lg:ml-auto pt-4 lg:pt-0 -mt-1 mr-6 w-32">
        <FormInputText v-model="$v.blind_quantity.$model" :has-error="$v.blind_quantity.$error"
                       :label="$t('calculators.quantity')"
                       :min="1"
                       :placeholder="$t('calculators.quantity')" borderColor="primary" identifier="blind_quantity"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind_quantity.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.quantity')}) }}
            </p>
            <p v-else-if="!$v.blind_quantity.minValue">
              {{ $t('validation.minimum_quantity', {x: '1'}) }}
            </p>
          </template>
        </FormInputText>
      </div>
      <div :class="[$route.query.token ? 'pt-4 lg:ml-auto lg:pt-0' : '']" class="price-container">
        <div class="price">
          <p class="px-4" style="min-width: 9rem;">€{{ totalCost }}<span v-if="blind_quantity > 1 && totalCost"> (Total: €{{totalCostWithQuantity}})</span></p>
        </div>
        <Button :class="{'spinner-black': is_adding_item}" :onclick="addToCart" className="--primary --small"
                type="submit">
          {{ $route.query.token ? $t('calculators.update') : ($route.query.quote ? $t('calculators.add_to_quote') : $t('calculators.add_to_cart')) }}
        </Button>
      </div>
    </div>

    <div class="form-container">
      <h1 class="form-title">{{ $t('calculators.measurements') }}</h1>

      <FormGroupFour class="form main-form">
        <FormInputText v-model="$v.blind.width.$model" :has-error="$v.blind.width.$error"
                       :label="$t('calculators.aperture_width') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.aperture_width')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.width.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_width')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputText v-model="$v.blind.drop.$model" :has-error="$v.blind.drop.$error"
                       :label="$t('calculators.aperture_drop') + ' (cm)'"
                       :placeholder="$t('calculators.aperture_drop')"
                       borderColor="primary" identifier="drop" type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.drop.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_drop')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputSelect v-model="$v.blind.aperture_type.$model"
                         :has-error="$v.blind.aperture_type.$error" :display-custom-label="(row) => row.charAt(0).toUpperCase() + row.slice(1)"
                         :label="$t('calculators.aperture_type')" :options="apertureTypeOptions"
                         :placeholder="$t('calculators.aperture_type')" identifier="aperture_type"
                         track-by="type">
          <template v-slot:errors>
            <p v-if="!$v.blind.aperture_type.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.aperture_type')}) }}
            </p>
          </template>
        </FormInputSelect>
      </FormGroupFour>

      <FormGroupFour class="form main-form">
        <FormInputSelect v-model="$v.blind.recess_type.$model" :has-error="$v.blind.recess_type.$error"
                         :label="$t('calculators.recess_type')"
                         :options="recessTypeOptions"
                         :placeholder="$t('calculators.recess_type')" identifier="recess-type"
                         type="number" @input="onSelectRecessType">
          <template v-slot:errors>
            <p v-if="!$v.blind.recess_type.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.recess_type')}) }}
            </p>
          </template>
        </FormInputSelect>

        <FormInputSelect v-if="blind.recess_type === 'Inside recess'"  v-model="$v.blind.width_clearance.$model" :disabled="blind.recess_type === 'Outside recess'"
                         :has-error="$v.blind.width_clearance.$error"
                         :label="$t('calculators.width_clearance')"
                         :options="clearanceOptions" :placeholder="$t('calculators.width_clearance')"
                         identifier="width-clearance"
                         type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.width_clearance.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.width_clearance')}) }}
            </p>
          </template>
        </FormInputSelect>

        <FormInputText v-if="blind.recess_type === 'Outside recess'"  v-model="$v.blind.overlap_width.$model" :has-error="$v.blind.overlap_width.$error"
                       :label="$t('calculators.outside_overlap_width') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.outside_overlap_width')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.overlap_width.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.outside_overlap_width')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputText v-if="blind.recess_type === 'Outside recess'" v-model="$v.blind.overlap_drop.$model" :has-error="$v.blind.overlap_drop.$error"
                       :label="$t('calculators.outside_overlap_drop') + ' (cm)'"
                       :max="240"
                       :placeholder="$t('calculators.outside_overlap_drop')" borderColor="primary" identifier="width"
                       type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.overlap_drop.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.outside_overlap_drop')}) }}
            </p>
          </template>
        </FormInputText>

        <FormInputSelect v-model="$v.blind.top_tube_fabric_roll.$model"
                         :has-error="$v.blind.top_tube_fabric_roll.$error" :display-custom-label="(row) => row.charAt(0).toUpperCase() + row.slice(1)"
                         :label="$t('calculators.top_tube_fabric_roll')" :options="topTubeFabricRollOptions"
                         :placeholder="$t('calculators.top_tube_fabric_roll')" identifier="top_tube_fabric_roll"
                         track-by="type">
          <template v-slot:errors>
            <p v-if="!$v.blind.top_tube_fabric_roll.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.top_tube_fabric_roll')}) }}
            </p>
          </template>
        </FormInputSelect>
      </FormGroupFour>

      <FormGroupFour class="form main-form">
        <FormInputSelect v-model="$v.blind.chain_side.$model" :has-error="$v.blind.chain_side.$error"
                         :label="$t('calculators.chain_side')"
                         :options="chainSideOptions"
                         :placeholder="$t('calculators.chain_side')" identifier="chain-side"
                         type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.chain_side.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.chain_side')}) }}
            </p>
          </template>
        </FormInputSelect>

        <FormInputSelect v-model="$v.blind.chain_type.$model" :disabled="!!motor_selected"
                         :display-custom-label="(row) => `${row.attributes.name}`"
                         :has-error="$v.blind.chain_type.$error"
                         :label="$t('calculators.chain_type')" :options="chainTypeOptions"
                         :placeholder="$t('calculators.chain_type')"
                         identifier="chain-type" type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.chain_type.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.chain_type')}) }}
            </p>
          </template>
        </FormInputSelect>
      </FormGroupFour>

      <FormGroupFour class="form">
        <FormInputText v-model="$v.blind.tile_cut_width.$model" :has-error="$v.blind.tile_cut_width.$error" :disabled="blind.recess_type === 'Outside recess'"
                       :label="$t('calculators.tile_cut_width') + ' (cm)'"
                       :placeholder="$t('calculators.tile_cut_width')"
                       identifier="tile-cut-width"
                       type="number">
          <template v-slot:errors>
          </template>
        </FormInputText>

        <FormInputSelect v-model="$v.blind.tile_cut_width_clearance.$model" :disabled="blind.recess_type === 'Outside recess'"
                         :has-error="$v.blind.tile_cut_width_clearance.$error"
                         :label="$t('calculators.tile_cut_width_clearance')" :options="tileCutWidthClearanceOptions"
                         :placeholder="$t('calculators.tile_cut_width_clearance')"
                         identifier="tile-cut-width-clearance"
                         type="number">
          <template v-slot:errors>
            <p v-if="!$v.blind.tile_cut_width_clearance.required">
              {{ $t('validation.x_is_required', {x: $t('calculators.tile_cut_width_clearance')}) }}
            </p>
          </template>
        </FormInputSelect>
      </FormGroupFour>

      <div class="divider"></div>

      <div class="form">

        <FormInputText v-model="note" :label="$t('calculators.note')" :largeTextarea="true"
                       :placeholder="$t('calculators.note')"
                       :useTextarea="true" class="note-field" identifier="note"></FormInputText>
      </div>
    </div>

    <div class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.options') }}</h1>
      <h2>{{ $t('calculators.fixing_type') }}</h2>
      <div class="item-container">
        <div v-for="cover in fixing_type_options" class="item-card-container">
          <div :class="{'selected': cover_option_selected === cover}" class="item-card"
               @click="onCoverSelect(cover)">
            <h1 class="item-card-title">{{ cover.name }}</h1>
          </div>
        </div>
      </div>

      <div v-if="pvc_bottom_bar_options.length || aluminium_bottom_bar_options.length" class="divider"></div>
      <div v-if="pvc_bottom_bar_options.length" class="heading-container">
        <h2>{{ $t('calculators.oval_pvc_bottom_bar') }}</h2>
        <font-awesome-icon v-tippy="{ placement : 'top',  arrow: true }"
                           :content="`<img src='https://global-public-assets.s3.eu-central-1.amazonaws.com/blindsanddrapes.com.mt/oval.png'/>`"
                           :icon="['far','info-circle']"/>
      </div>
      <div class="item-container bottom-bar-container">
        <div v-for="option in pvc_bottom_bar_options" class="item-card-container">
          <div :class="{'selected': bottom_bar_selected === option}" class="item-card"
               @click="onBottomBarSelect(option)">
            <h1 class="item-card-title">{{ option.attributes.name }}</h1>
          </div>
        </div>
      </div>

      <div v-if="aluminium_bottom_bar_options.length" class="heading-container">
        <h2>{{ $t('calculators.round_aluminium_bottom_bar') }}</h2>
        <font-awesome-icon v-tippy="{ placement : 'top',  arrow: true }"
                           :content="`<img width='100' src='https://global-public-assets.s3.eu-central-1.amazonaws.com/blindsanddrapes.com.mt/bottom_bar.png'/><img width='100' src='https://global-public-assets.s3.eu-central-1.amazonaws.com/blindsanddrapes.com.mt/round.png'/>`"
                           :icon="['far','info-circle']"/>
      </div>
      <div class="item-container">
        <div v-for="option in aluminium_bottom_bar_options" class="item-card-container">
          <div :class="{'selected': bottom_bar_selected === option}" class="item-card"
               @click="onBottomBarSelect(option)">
            <h1 class="item-card-title">{{ option.attributes.name }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.fabrics') }}</h1>
      <div class="item-container">
        <div v-for="fabric in fabrics" class="item-card-container">
          <div :class="{'selected': fabric_selected === fabric}" class="item-card"
               @click="onFabricSelect(fabric)">
            <h1 class="item-card-title">{{ fabric.attributes.name }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div v-if="fabric_selected" class="form-container cards">
      <h1 class="form-title">{{ $t('calculators.fabric_colours') }}</h1>
      <div class="item-container">
        <div v-for="colour in JSON.parse(fabric_selected.attributes.colors)" class="item-card-container">
          <div :class="{'selected': colour_selected === colour}" class="item-card"
               @click="onColourSelect(colour)">
            <h1 class="item-card-title">{{ colour }}</h1>
          </div>
        </div>
      </div>
      <FormInputText v-show="colour_selected === 'Custom Colour'" v-model="$v.custom_colour_code.$model"
                     :has-error="$v.custom_colour_code.$error"
                     :label="$t('calculators.custom_colour_code')" :placeholder="$t('calculators.custom_colour_code')" class="custom-colour-field"
                     identifier="custom-colour-code"
                     type="text">
        <template v-slot:errors>
          <p v-if="!$v.custom_colour_code.required">
            {{ $t('validation.x_is_required', {x: $t('calculators.custom_colour_code')}) }}
          </p>
        </template>
      </FormInputText>
    </div>

    <div v-if="motors.length || controller_types.length" class="form-container cards">
      <h1 class="form-title">Home Automation</h1>
      <div class="category-container">
        <h2>Motors</h2>

        <div class="item-container">
          <div v-for="motor in motors" class="item-card-container">
            <div :class="{'selected': motor === motor_selected, 'disabled': motor.disabled}" class="item-card"
                 @click="onMotorSelected(motor)">
              <h1 class="item-card-title">{{ motor.attributes.name }}</h1>
            </div>
          </div>
        </div>

        <div v-if="controller_types.length > 0" class="divider"></div>

        <h2>Controller Type</h2>

        <div v-if="motor_selected && motor_selected.attributes && motor_selected.attributes.name === 'Electrical Switch Operated Motor M6 6Nm'"
             class="item-container">
          <div v-for="controller_type in controller_types" class="item-card-container">
            <div class="item-card disabled">
              <h1 class="item-card-title">{{ controller_type.attributes.name }}</h1>
            </div>
          </div>
        </div>
        <div v-else class="item-container">
          <div v-for="controller_type in controller_types" class="item-card-container">
            <div :class="{'selected': controller_types_selected.find(c => c.attributes.code === controller_type.attributes.code)}" class="item-card"
                 @click="onControllerTypeSelected(controller_type)">
              <h1 class="item-card-title">{{ controller_type.attributes.name }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputText from "@/components/form/FormInputText";
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormGroupFour from "@/components/form/FormGroupFour";
import FormInputSelect from "@/components/form/FormInputSelect";
import Button from "@/components/Button";
import {minValue, required, requiredIf} from "vuelidate/lib/validators";
import LoadingScreen from "@/components/LoadingScreen";
import {createArrayPayloadFromBlind} from "@/utils/BlindUtils";

export default {
  name: "RollerBlind3",
  components: {LoadingScreen, Button, FormInputSelect, FormGroupFour, FormInputText, FormGroupTwo},
  data() {
    return {
      blind: {
        width: null,
        drop: null,
        overlap_width: null,
        overlap_drop: null,
        aperture_type: null,
        recess_type: null,
        top_tube_fabric_roll: null,
        width_clearance: null,
        chain_side: null,
        chain_type: null,
        tile_cut_width: null,
        tile_cut_width_clearance: null,
      },
      blind_quantity: 1,
      note: null,

      apertureTypeOptions: ['door', 'window'],
      recessTypeOptions: ['Outside recess', 'Inside recess'],
      clearanceOptions: ['0.5 cm', '1 cm', '1.5 cm', '2 cm'],
      tileCutWidthClearanceOptions: ['0.5 cm', '1 cm', '1.5 cm', '2 cm'],
      chainSideOptions: ['Left', 'Right'],
      chainTypeOptions: [],
      fixing_type_options: [
        {
          name: 'Brackets',
          type: 'brackets'
        },
        {
          name: 'Aluminium Back Bar',
          type: 'aluminium_back_bar'
        },
        {
          name: 'Aluminium Cover',
          type: 'aluminium_cover'
        }
      ],
      topTubeFabricRollOptions: ['Outside Recess Roll', 'Inside Recess Roll'],
      fabrics: [],
      pvc_bottom_bar_options: [],
      aluminium_bottom_bar_options: [],

      fabric_selected: null,
      colour_selected: null,
      cover_option_selected: null,
      custom_colour_code: null,
      bottom_bar_selected: null,

      calculator: {
        attributes: {
          name: 'RollerBlind',
        }
      },
      categories: [],
      motors: [],
      controller_types: [],
      motor_selected: null,
      controller_types_selected: [],
      per_cm_items: [],
      cover_per_cm_items: [],
      backbar_per_cm_items: [],
      per_unit_items: [],
      cover_per_unit_items: [],
      backbar_per_unit_items: [],
      brackets_per_cm_items: [],
      brackets_per_unit_items: [],
      blind_details: null,
      is_loading_blind: false,
      is_loading_fabrics: false,
      is_loading_articles: false,
      is_adding_item: false,
    }
  },
  validations: {
    blind: {
      width: {required,},
      drop: {required,},
      aperture_type: {required},
      recess_type: {required},
      top_tube_fabric_roll: {required},
      width_clearance: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Inside recess';
        })
      },
      chain_side: {required},
      chain_type: {
        required: requiredIf(function () {
          return !this.motor_selected;
        })
      },
      tile_cut_width: {},
      tile_cut_width_clearance: {
        required: requiredIf(function () {
          return this.blind.tile_cut_width;
        })
      },
      overlap_width: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Outside recess';
        })
      },
      overlap_drop: {
        required: requiredIf(function () {
          return this.blind.recess_type === 'Outside recess';
        })
      },
    },
    blind_quantity: {required: required, minValue: minValue(1)},
    custom_colour_code: {
      required: requiredIf(function () {
        return this.colour_selected === 'Custom Colour';
      })
    }
  },
  watch: {
    blind: {
      handler(newValue, oldValue) {
        if (newValue.recess_type === 'Outside recess') {
          this.blind.width_clearance = null;
        }
        if (newValue.recess_type === 'Inside recess') {
          this.blind.overlap_drop = null;
          this.blind.overlap_width = null;
        }
      },
      deep: true
    }
  },
  methods: {
    onFabricSelect(fabric) {
      this.fabric_selected = fabric;
    },
    onColourSelect(colour) {
      this.colour_selected = colour;
    },
    onCoverSelect(cover) {
      this.cover_option_selected = cover;
    },
    onBottomBarSelect(option) {
      this.bottom_bar_selected = option;
    },
    onMotorSelected(motor) {
      if (motor === this.motor_selected) {
        this.motor_selected = null;
      } else {
        this.motor_selected = motor;

        //Disable and clear chain type if any motor is selected
        this.blind.chain_type = null;
      }

      if (this.motor_selected && this.motor_selected.attributes && this.motor_selected.attributes.name && this.motor_selected.attributes.name === "Electrical Switch Operated Motor M6 6Nm") {
        this.controller_types_selected = [];
      }
    },
    onControllerTypeSelected(controller_type) {
      if (!this.controller_types_selected.find(c => c.attributes.code === controller_type.attributes.code))
        this.controller_types_selected.push(controller_type);
      else {
        this.controller_types_selected.splice(this.controller_types_selected.findIndex(c => c.attributes.code === controller_type.attributes.code), 1);
      }
    },
    onSelectRecessType(recess) {
      if (recess === 'Outside recess') {
        this.blind.tile_cut_width = null
        this.blind.tile_cut_width_clearance = null
        this.blind.top_tube_fabric_roll = this.topTubeFabricRollOptions.find(x => x.toLowerCase().includes('outside'))
      }
      else if (recess === 'Inside recess') {
        this.blind.top_tube_fabric_roll = this.topTubeFabricRollOptions.find(x => x.toLowerCase().includes('inside'))
      }
    },
    unitsCM2(fabric) {
      return +(Math.round((this.apertureDropWithOverlap * this.apertureWidthWithOverlap * fabric.attributes.multiplier) + "e+2") + "e-2");
    },
    retrieveBlind() {
      this.is_loading_blind = true;

      this.$axios.get(`blinds/${this.$route.query.id}`).then(({data}) => {
        this.blind_details = data.data;
        this.is_loading_blind = false;
      }).catch(e => {
        this.is_loading_blind = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_blind')),
          type: 'error',
        });
      });
    },
    retrieveFabrics() {
      this.is_loading_fabrics = true;

      this.$axios.get(`blinds/${this.$route.query.id}/fabrics`).then(({data}) => {
        this.fabrics = data.data.sort((a, b) => a.attributes.order - b.attributes.order);
        this.is_loading_fabrics = false;
      }).catch(e => {
        this.is_loading_fabrics = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_fabrics')),
          type: 'error',
        });
      });
    },
    async retrieveArticles() {
      this.is_loading_articles = true;

      await this.$axios.get(`blinds/${this.$route.query.id}/articles/list`).then(async ({data}) => {
        this.articles = data.data;

        await this.articles.forEach(article => {
          if (article.attributes.unit === 'per_cm') {
            if (article.relationships.category.data.length === 0 || (article.relationships.category && article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name !== 'Small Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Large Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Aluminium Backbar' && article.relationships.category.data.attributes.name !== 'Brackets' && article.relationships.category.data.attributes.name !== 'Oval PVC Bottom Bars' && article.relationships.category.data.attributes.name !== 'Round Aluminium Bottom Bars' && article.relationships.category.data.attributes.name !== 'Chain Types'))
              this.per_cm_items.push(article);
            else if (article.relationships.category.data.attributes.name === 'Small Aluminium Cassette' || article.relationships.category.data.attributes.name === 'Large Aluminium Cassette')
              this.cover_per_cm_items.push(article);
            else if (article.relationships.category.data.attributes.name === 'Aluminium Backbar')
              this.backbar_per_cm_items.push(article);
            else if (article.relationships.category.data.attributes.name === 'Brackets')
              this.brackets_per_cm_items.push(article);
          }

          if (article.attributes.unit === 'per_unit') {
            if (article.relationships.category.data.length === 0 || (article.relationships.category && article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name !== 'Small Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Large Aluminium Cassette' && article.relationships.category.data.attributes.name !== 'Aluminium Backbar' && article.relationships.category.data.attributes.name !== 'Brackets' && article.relationships.category.data.attributes.name !== 'Oval PVC Bottom Bars' && article.relationships.category.data.attributes.name !== 'Round Aluminium Bottom Bars' && article.relationships.category.data.attributes.name !== 'Chain Types'))
              this.per_unit_items.push(article);
            else if (article.relationships.category.data.attributes.name === 'Small Aluminium Cassette' || article.relationships.category.data.attributes.name === 'Large Aluminium Cassette')
              this.cover_per_unit_items.push(article);
            else if (article.relationships.category.data.attributes.name === 'Aluminium Backbar')
              this.backbar_per_unit_items.push(article);
            else if (article.relationships.category.data.attributes.name === 'Brackets')
              this.brackets_per_unit_items.push(article);
          }

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Fixing Types')
            this.fixing_type = article;

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Motors')
            this.motors.push(article);

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Controller Types')
            this.controller_types.push(article);

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Chain Types')
            this.chainTypeOptions.push(article);

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Oval PVC Bottom Bars')
            this.pvc_bottom_bar_options.push(article);

          if (article.relationships.category.data && article.relationships.category.data.attributes && article.relationships.category.data.attributes.name === 'Round Aluminium Bottom Bars')
            this.aluminium_bottom_bar_options.push(article);
        });

        this.is_loading_articles = false;
      }).catch(e => {
        this.is_loading_articles = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('calculators.error_failed_retrieve_articles')),
          type: 'error',
        });
      });
    },
    async getOrder() {
      let order = 0;

      if (!this.$route.query.token) {
        if (this.$route.query.quote) {
          let order = 0;

          await this.$axios.get(`/quotes/${this.$route.query.quote}`).then(({data}) => {
            if (data.data.relationships.blinds.data.length)
              data.data.relationships.blinds.data.forEach(blind => {
                if (blind.attributes.order > order)
                  order = blind.attributes.order;
              })
          });

          return order += 1;
        } else if (this.cart) {
          this.cart.relationships.blinds.data.forEach(blind => {
            if (blind.attributes.order > order)
              order = blind.attributes.order;
          })
        }

        return order += 1;
      } else {
        return order = this.$route.query.cart_order ? this.$route.query.cart_order : 0;
      }
    },
    async updateCartOrder(payload) {
      await this.$axios.put(`carts/${this.$route.query.cart}/blinds/detach`, {token: this.$route.query.token})
          .then(({data}) => {
          })
          .catch(e => {
            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
              type: 'error',
            });
          });

      await this.$axios.put(`carts/${this.$route.query.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
          type: 'error',
        });
      });
    },
    async updateQuote(payload) {
      this.is_adding_item = true;

      if (this.$route.query.token)
        await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/detach`, {token: this.$route.query.token})
            .then(({data}) => {
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('quotes.error_remove_from_quote')),
                type: 'error',
              });
            });

      await this.$axios.put(`quotes/${this.$route.query.quote}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {
        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('quotes.error_add_to_quote')),
          type: 'error',
        });
      });
    },
    async getCart() {
      this.is_loading_cart = true;

      await this.$axios.get(`carts/${this.$store.getters.cart}`)
          .then(({data}) => {
            this.cart = data.data;
            this.is_loading_cart = false;
          })
          .catch(e => {
            this.is_loading_cart = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
              type: 'error',
            });
          });
    },
    async addToCart() {
      this.$v.blind.$touch();
      this.$v.custom_colour_code.$touch();

      if (this.$v.blind.$anyError || this.$v.custom_colour_code.$anyError || this.is_adding_item) {
        return
      }

      if (this.motor_selected && this.blind.width < 70) {
        this.$notify({
          text: 'Width cannot be smaller than 70cm',
          type: 'error',
        });
        return;
      }

      if (this.blind.width > 300) {
        this.$notify({
          text: 'Width cannot be larger than 300cm',
          type: 'error',
        });
        return;
      }

      if (this.blind.width <= 0) {
        this.$notify({
          text: 'Width cannot be smaller than 1cm',
          type: 'error',
        });
        return;
      }

      if (this.blind.drop <= 0) {
        this.$notify({
          text: 'Drop cannot be smaller than 1cm',
          type: 'error',
        });
        return;
      }

      if (!this.cover_option_selected) {
        this.$notify({
          text: this.$t('calculators.fixing_type_required'),
          type: 'error',
        });
        return;
      }

      if (!this.bottom_bar_selected) {
        this.$notify({
          text: this.$t('calculators.bottom_bar_required'),
          type: 'error',
        });
        return;
      }

      if (!this.fabric_selected) {
        this.$notify({
          text: this.$t('calculators.fabric_required'),
          type: 'error',
        });
        return;
      }

      if (!this.colour_selected) {
        this.$notify({
          text: this.$t('calculators.fabric_colour_required'),
          type: 'error',
        });
        return;
      }

      if (this.$store.getters.cart)
        await this.getCart();

      let order = await this.getOrder();

      let payload = {
        order: order,
        note: this.note,
        value: this.totalCost,
        options: [
          {
            width: this.blind.width,
            drop: this.blind.drop,
            overlap_drop: this.blind.overlap_drop,
            overlap_width: this.blind.overlap_width,
            aperture_type: this.blind.aperture_type,
            recess_type: this.blind.recess_type,
            top_tube_fabric_roll: this.blind.top_tube_fabric_roll,
            width_clearance: this.blind.width_clearance,
            chain_side: this.blind.chain_side,
            tile_cut_width: this.blind.tile_cut_width,
            tile_cut_width_clearance: this.blind.tile_cut_width_clearance,
            fabric_type: this.fabric_selected.attributes.name,
            fabric_colour: this.colour_selected,
            custom_colour_code: this.custom_colour_code,
            fixing_type: this.cover_option_selected.type,
          }
        ],
        is_unavailable: false
      }

      if (this.blind.chain_type)
        payload.options[0].chain_type = this.blind.chain_type.attributes.name;

      if (this.bottom_bar_selected)
        payload.options[0].bottom_bar = this.bottom_bar_selected.attributes.name;

      if (this.motor_selected)
        payload.options[0].motor = this.motor_selected.attributes.name;

      if (this.controller_types_selected.length)
        payload.options[0].controller_types = this.controller_types_selected;

      this.is_adding_item = true;

      if (this.$route.query.cart && this.$route.query.order) {
        await this.updateCartOrder(payload);

        this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});

        return;
      }

      if (this.$route.query.quote) {
        await this.updateQuote(payload);

        if (this.$route.query.order) this.$router.push({path: `/calculators/my-orders/${this.$route.query.order}/order-details`});
        else this.$router.push({path: `/calculators/quotes/${this.$route.query.quote}/quote-details`});

        return;
      }

      if (!this.$store.getters.cart) {
        await this.$store.dispatch('createCart');
      }


      if (this.$route.query.token) {
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds/detach`, {token: this.$route.query.token})
            .then(({data}) => {
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_remove_from_cart')),
                type: 'error',
              });
            });
      }

      if (!this.$route.query.token && !this.$route.query.quote && !this.$route.query.cart_order && this.blind_quantity > 1) {
        let tempBlinds = createArrayPayloadFromBlind(payload, this.blind_quantity)
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach/many`, {blinds: tempBlinds}).then(({data}) => {

          this.$store.commit('SET_CART_COUNT', (Number(this.$store.getters.cartCount) + Number(this.blind_quantity)).toString())

          this.$notify({
            text: this.$t('cart.item_added_to_cart'),
            type: 'success',
          });

          this.blind = {
            width: null,
            drop: null,
            overlap_drop: null,
            overlap_width: null,
            aperture_type: null,
            recess_type: null,
            top_tube_fabric_roll: null,
            width_clearance: null,
            chain_side: null,
            chain_type: null,
            tile_cut_width: null,
            tile_cut_width_clearance: null,
          }

          this.fabric_selected = null;
          this.colour_selected = null;
          this.custom_colour_code = null;
          this.cover_option_selected = null;
          this.bottom_bar_selected = null;
          this.motor_selected = null;
          this.controller_type_selected = null;
          this.controller_types_selected = [];
          this.note = null;
          this.blind_quantity = 1;

          this.$v.blind.$reset();
          this.$v.custom_colour_code.$reset();

          this.is_adding_item = false;
        }).catch(e => {
          this.is_adding_item = false;

          this.$notify({
            text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
            type: 'error',
          });
        });
        return
      }

      this.$axios.put(`carts/${this.$store.getters.cart}/blinds/${this.$route.query.id}/attach`, payload).then(({data}) => {

        if (!this.$route.query.token)
          this.$store.commit('INCREMENT_CART_COUNT');

        if (this.$route.query.token) {
          this.$router.push({name: 'cart'});
        }

        this.$notify({
          text: this.$t('cart.item_added_to_cart'),
          type: 'success',
        });

        this.blind = {
          width: null,
          drop: null,
          overlap_drop: null,
          overlap_width: null,
          aperture_type: null,
          recess_type: null,
          top_tube_fabric_roll: null,
          width_clearance: null,
          chain_side: null,
          chain_type: null,
          tile_cut_width: null,
          tile_cut_width_clearance: null,
        }

        this.fabric_selected = null;
        this.colour_selected = null;
        this.custom_colour_code = null;
        this.cover_option_selected = null;
        this.bottom_bar_selected = null;
        this.motor_selected = null;
        this.controller_type_selected = null;
        this.controller_types_selected = [];
        this.note = null;
        this.blind_quantity = 1;

        this.$v.blind.$reset();
        this.$v.custom_colour_code.$reset();

        this.is_adding_item = false;
      }).catch(e => {
        this.is_adding_item = false;

        this.$notify({
          text: this.$larerror(e.response.data.errors, this.$t('cart.error_add_to_cart')),
          type: 'error',
        });
      });
    },
    populate() {
      if (this.$route.query.quote) {
        this.$axios.put(`quotes/${this.$route.query.quote}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.top_tube_fabric_roll = details.top_tube_fabric_roll;
              this.blind.width_clearance = details.width_clearance;
              this.blind.chain_side = details.chain_side;

              this.chainTypeOptions.forEach(option => {
                if (option.attributes.name === details.chain_type) {
                  this.blind.chain_type = option;
                }
              });

              this.blind.tile_cut_width = details.tile_cut_width;

              if (details.tile_cut_width_clearance)
                this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;


              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              this.fixing_type_options.forEach(option => {
                if (option.type === details.fixing_type || option.name === details.fixing_type)
                  this.cover_option_selected = option;
              })

              this.aluminium_bottom_bar_options.forEach(option => {
                if (option.attributes.name === details.bottom_bar)
                  this.bottom_bar_selected = option;
              })

              this.pvc_bottom_bar_options.forEach(option => {
                if (option.attributes.name === details.bottom_bar)
                  this.bottom_bar_selected = option;
              })

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

              if (details.controller_types)
                this.controller_types_selected = details.controller_types;
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
                type: 'error',
              });
            });
      } else if (this.$route.query.cart) {
        this.$axios.put(`carts/${this.$route.query.cart}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.top_tube_fabric_roll = details.top_tube_fabric_roll;
              this.blind.width_clearance = details.width_clearance;
              this.blind.chain_side = details.chain_side;

              this.chainTypeOptions.forEach(option => {
                if (option.attributes.name === details.chain_type) {
                  this.blind.chain_type = option;
                }
              });

              this.blind.tile_cut_width = details.tile_cut_width;

              if (details.tile_cut_width_clearance)
                this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;


              if (data.note)
                this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              this.fixing_type_options.forEach(option => {
                if (option.type === details.fixing_type || option.name === details.fixing_type)
                  this.cover_option_selected = option;
              })

              this.aluminium_bottom_bar_options.forEach(option => {
                if (option.attributes.name === details.bottom_bar)
                  this.bottom_bar_selected = option;
              })

              this.pvc_bottom_bar_options.forEach(option => {
                if (option.attributes.name === details.bottom_bar)
                  this.bottom_bar_selected = option;
              })

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

              if (details.controller_types)
                this.controller_types_selected = details.controller_types;
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
                type: 'error',
              });
            });
      } else {
        this.$axios.put(`carts/${this.$store.getters.cart}/blinds`, {token: this.$route.query.token})
            .then(({data}) => {
              const details = JSON.parse(data.options)[0];

              this.blind.width = details.width;
              this.blind.drop = details.drop;
              this.blind.aperture_type = details.aperture_type;
              this.blind.recess_type = details.recess_type;
              this.blind.top_tube_fabric_roll = details.top_tube_fabric_roll;
              this.blind.width_clearance = details.width_clearance;
              this.blind.chain_side = details.chain_side;

              this.chainTypeOptions.forEach(option => {
                if (option.attributes.name === details.chain_type) {
                  this.blind.chain_type = option;
                }
              });

              this.blind.tile_cut_width = details.tile_cut_width;

              if (details.tile_cut_width_clearance)
                this.blind.tile_cut_width_clearance = details.tile_cut_width_clearance;

              if (details.overlap_width != null)
                this.blind.overlap_width = details.overlap_width;

              if (details.overlap_drop != null)
                this.blind.overlap_drop = details.overlap_drop;


              // if (data.note)
              //     this.note = data.note;

              this.fabrics.forEach(fabric => {
                if (fabric.attributes.name === details.fabric_type)
                  this.fabric_selected = fabric;
              })

              JSON.parse(this.fabric_selected.attributes.colors).forEach(colour => {
                if (colour === details.fabric_colour)
                  this.colour_selected = details.fabric_colour;
              })

              if (details.custom_colour_code)
                this.custom_colour_code = details.custom_colour_code;

              this.fixing_type_options.forEach(option => {
                if (option.type === details.fixing_type || option.name === details.fixing_type)
                  this.cover_option_selected = option;
              })

              this.aluminium_bottom_bar_options.forEach(option => {
                if (option.attributes.name === details.bottom_bar)
                  this.bottom_bar_selected = option;
              })

              this.pvc_bottom_bar_options.forEach(option => {
                if (option.attributes.name === details.bottom_bar)
                  this.bottom_bar_selected = option;
              })

              if (details.motor)
                this.motors.forEach(option => {
                  if (option.attributes.name === details.motor)
                    this.motor_selected = option;
                })

              if (details.controller_type)
                this.controller_types.forEach(option => {
                  if (option.attributes.name === details.controller_type)
                    this.controller_type_selected = option;
                })

              if (details.controller_types)
                this.controller_types_selected = details.controller_types;
            })
            .catch(e => {
              this.$notify({
                title: this.$t('error'),
                text: this.$larerror(e.response.data, this.$t('cart.error_retrieve_cart')),
                type: 'error',
              });
            });
      }
    }
  },
  computed: {
    apertureWidthWithOverlap() {
      return (this.blind.width ? parseFloat(this.blind.width) : 0) + (this.blind.overlap_width ? parseFloat(this.blind.overlap_width) : 0)
    },
    apertureDropWithOverlap() {
      return (this.blind.drop ? parseFloat(this.blind.drop) : 0) + (this.blind.overlap_drop ? parseFloat(this.blind.overlap_drop) : 0)
    },
    blindCost() {
      let total = 0;

      this.per_cm_items.forEach(item => {
        if (item.attributes.on_width)
          total += item.attributes.price * item.attributes.multiplier * this.apertureWidthWithOverlap;
      })

      this.per_unit_items.forEach(item => {
        if (!item.attributes.is_ignored || (item.attributes.is_ignored && !this.motor_selected))
          total += item.attributes.price * item.attributes.quantity;
      });

      return total;
    },
    totalCostWithQuantity() {
      return this.blind_quantity * this.totalCost
    },
    fabricCost() {
      if (this.fabric_selected) {
        let fabric_price = this.fabric_selected.attributes.price;

        if (this.apertureWidthWithOverlap > this.fabric_selected.attributes.no_wastage_limit) {
          fabric_price *= (1 + this.fabric_selected.attributes.wastage / 100)
        }

        return fabric_price * this.unitsCM2(this.fabric_selected) / 10000;

      } else return 0;
    },
    articlesCost() {
      let total = 0;

      if (this.blind.chain_type)
        total += this.blind.chain_type.attributes.price;

      if (this.motor_selected)
        total += this.motor_selected.attributes.price;

      if (this.controller_type_selected)
        total += this.controller_type_selected.attributes.price;

      if (this.controller_types_selected)
        this.controller_types_selected.forEach(c => {
          total += c.attributes.price;
        })

      return total;
    },
    bottomBarCost() {
      let total = 0;

      if (this.bottom_bar_selected) {
        if (this.bottom_bar_selected.attributes.unit === 'per_cm' && this.bottom_bar_selected.attributes.on_width)
          total += this.bottom_bar_selected.attributes.price * this.bottom_bar_selected.attributes.multiplier * this.apertureWidthWithOverlap;
        else if (this.bottom_bar_selected.attributes.unit === 'per_cm' && this.bottom_bar_selected.attributes.on_drop) {
          total += this.bottom_bar_selected.attributes.price * this.bottom_bar_selected.attributes.multiplier * this.apertureDropWithOverlap;
        }
      }

      return total;
    },
    coverCost() {
      let total = 0;

      if (this.cover_option_selected && this.cover_option_selected.type === 'aluminium_cover') {
        this.cover_per_cm_items.forEach(item => {
          total += item.attributes.price * item.attributes.multiplier * this.apertureWidthWithOverlap;
        });

        this.cover_per_unit_items.forEach(item => {
          total += item.attributes.price * item.attributes.quantity;
        })
      } else if (this.cover_option_selected && this.cover_option_selected.type === 'aluminium_back_bar') {
        this.backbar_per_cm_items.forEach(item => {
          total += item.attributes.price * item.attributes.multiplier * this.apertureWidthWithOverlap;
        });

        this.backbar_per_unit_items.forEach(item => {
          total += item.attributes.price * item.attributes.quantity;
        })
      } else if (this.cover_option_selected && this.cover_option_selected.type === 'brackets') {
        this.brackets_per_cm_items.forEach(item => {
          total += item.attributes.price * item.attributes.multiplier * this.apertureWidthWithOverlap;
        });

        this.brackets_per_unit_items.forEach(item => {
          total += item.attributes.price * item.attributes.quantity;
        })
      }

      return total;
    },
    totalCost() {
      if (this.fabric_selected)
        return Math.ceil(this.blindCost + this.fabricCost + this.articlesCost + this.coverCost + this.bottomBarCost);
      else return 0;
    }
  },
  async mounted() {
    await this.retrieveBlind();
    await this.retrieveFabrics();
    await this.retrieveArticles();

    if (this.$route.query.token) this.populate();
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  @apply px-8 max-w-6xl mx-auto bg-white flex flex-col;

  & > .header-container {
    @apply flex flex-col sticky top-0 bg-white py-8;
    z-index: 999998;

    @screen lg {
      @apply flex-row
    }

    .title-container {
      @apply flex flex-row items-center;

      a {
        @apply text-primary text-2xl cursor-pointer;

        svg {

          &:hover {
            animation: spin 0.2s linear;
            animation-direction: reverse;

          }
        }
      }

      h1 {
        @apply font-bold text-center text-2xl ml-6;

        @screen lg {
          @apply text-3xl max-w-full ml-6;
        }
      }
    }

    .price-container {
      @apply flex flex-col items-center lg:mt-4;

      @screen lg {
        @apply mt-0 flex-row;
      }

      .price {
        @apply bg-white border-2 border-grey-light rounded py-2 w-full lg:w-5/12 lg:mr-8;

        @screen lg {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
        }

        p {
          @apply text-center text-black text-xl font-bold ;
          white-space: nowrap;
        }
      }

      button {
        @apply w-full lg:w-7/12 mt-4 lg:mt-0 h-12;
      }
    }
  }

  & > .form-container {
    @apply bg-grey-form border-2 border-grey-form rounded-xl mb-8 p-6;

    @screen md {
      @apply p-8;
    }

    @screen lg {
      @apply p-10;
    }

    .form-title {
      @apply text-xl font-bold mb-8;
    }

    h2 {
      @apply text-sm text-black font-bold mb-8;
    }

    .heading-container {
      @apply flex flex-row mb-8;

      h2 {
        @apply mb-0;
      }

      svg {
        @apply ml-4 text-grey-dark text-lg my-auto;
      }
    }

    .form {
      @apply -mb-4;

      &.main-form {
        @apply mb-2;

        .input-group {
          .input {
            width: 50% !important;
            border-color: theme('colors.primary') !important;
          }
        }
      }
    }

    .divider {
      @apply mx-auto border-t-2 border-grey-light rounded-md my-8 w-full;

      @screen lg {
        @apply mx-auto;
      }
    }

    .form-wrapper {
      @apply flex flex-col w-full;

      @screen lg {
        @apply flex-row;
      }

      .textboxes-container {
        @apply flex flex-col;

        @screen lg {
          @apply w-2/5 mr-2;
        }

        @screen xl {
          @apply w-1/4;
        }

        .input-group {
          @apply w-full;
        }
      }

      .note-field {
        @apply w-full;

        @screen lg {
          @apply w-3/5 ml-2;
        }

        @screen xl {
          @apply w-3/4;
        }
      }
    }

    .custom-colour-field {
      @apply mb-0 mt-8 max-w-sm;
    }
  }

  & > .title {
    @apply font-bold my-4;
  }

  .item-container {
    @apply flex flex-row flex-wrap -m-4;

    .item-card-container {
      @apply w-1/2 p-4;

      @screen md {
        @apply w-1/4;
      }

      @screen lg {
        @apply w-1/5;
      }

      .item-card {
        @apply w-full bg-white rounded-xl flex flex-col h-24 max-w-full px-4 cursor-pointer border-white border-3;
        box-shadow: 0px 10px 15px #00000012;

        &:hover {
          @apply border-primary;
        }

        &.selected {
          @apply border-primary;
        }

        .item-card-title {
          @apply text-center m-auto text-sm font-bold;

          @screen lg {
            @apply text-base;
          }
        }

        .price {
          @apply text-center m-auto text-sm font-bold;

          @screen lg {
            @apply text-base;
          }
        }

        &.disabled {
          @apply cursor-not-allowed bg-grey-light border-grey-light;

        }
      }
    }

    &.bottom-bar-container {
      @apply mb-8;
    }
  }
}
</style>