<template>
  <div>

    <div :class="{'error': item.attributes.is_unavailable}" class="item-card">
      <div class="card-title-container">
        <p class="card-title">{{ item.attributes.name }}</p>
        <div class="right">
          <p class="card-title">€{{ item.attributes.value }}</p>
          <div class="box"></div>
        </div>
      </div>

      <div class="first-section-container">
        <div v-if="item.attributes.options[0].type" class="half-section-container">
          <div class="section-container">
            <p class="section-title">Type</p>
            <p class="section-info">{{ item.attributes.options[0].type }}</p>
          </div>
        </div>

        <div
            :class="[item.attributes.options[0].aperture_type ? 'multiple-section-container-three' : 'half-section-container']">
          <div v-if="item.attributes.options[0].width" class="section-container">
            <p class="section-title">Aperture Width</p>
            <p class="section-info">{{ item.attributes.options[0].width }} cm</p>
          </div>
          <div v-if="item.attributes.options[0].drop" class="section-container">
            <p class="section-title">Aperture Drop</p>
            <p class="section-info">{{ item.attributes.options[0].drop }} cm</p>
          </div>
          <div v-if="item.attributes.options[0].aperture_type" class="section-container">
            <p class="section-title">Aperture Type</p>
            <p class="section-info capitalize">{{ item.attributes.options[0].aperture_type }}</p>
          </div>
          <div v-if="item.attributes.options[0].blind_type" class="section-container">
            <p class="section-title">Blind Type</p>
            <p class="section-info">{{ item.attributes.options[0].blind_type }}</p>
          </div>
        </div>

        <div
            v-if="(item.attributes.type.includes('venetian') || item.attributes.type.includes('curtain') || item.attributes.type.includes('vertical')) && (item.attributes.options[0].recess_type || item.attributes.options[0].wind_stopper_set)"
            class="multiple-section-container-wrap">
          <div v-if="item.attributes.options[0].recess_type" class="section-container">
            <p class="section-title">Recess Type</p>
            <p class="section-info">{{ item.attributes.options[0].recess_type }}</p>
          </div>
          <div v-if="item.attributes.options[0].wind_stopper_set && item.attributes.type.includes('venetian')" class="section-container">
            <p class="section-title">Wind Stopper Set</p>
            <p class="section-info">{{ item.attributes.options[0].wind_stopper_set }}</p>
          </div>

        </div>


        <div v-if="((item.attributes.options[0].slat_qty || item.attributes.options[0].runner_qty) && !item.attributes.type.includes('vertical') && !item.attributes.type.includes('curtain') && !item.attributes.type.includes('venetian')) ||
        (item.attributes.options[0].opening_type && !item.attributes.type.includes('curtain')) ||
        (item.attributes.options[0].opening_type && item.attributes.options[0].controls_side && item.attributes.type.includes('vertical'))"
             class="half-section-container">
          <div v-if="item.attributes.options[0].slat_qty && !item.attributes.type.includes('vertical') && !item.attributes.type.includes('curtain') && !item.attributes.type.includes('venetian')" class="section-container">
            <p class="section-title">Slat Qty</p>
            <div class="row">
              <p class="section-info">{{ item.attributes.options[0].slat_qty }}</p>
              <div class="box"></div>
            </div>
          </div>

          <div
              v-if="item.attributes.options[0].runner_qty && !item.attributes.type.includes('vertical') && !item.attributes.type.includes('curtain')"
              class="section-container">
            <p class="section-title">Runner Qty</p>
            <div class="row">
              <p class="section-info">{{ item.attributes.options[0].runner_qty }}</p>
              <div class="box"></div>
            </div>
          </div>

          <div v-if="item.attributes.options[0].controls_side && item.attributes.type.includes('vertical')"
               class="section-container">
            <p class="section-title">Controls Side</p>
            <p class="section-info">{{ item.attributes.options[0].controls_side }}</p>
          </div>

          <div v-if="item.attributes.options[0].opening_type && !item.attributes.type.includes('curtain')"
               class="section-container">
            <p class="section-title">Opening Type</p>
            <p class="section-info">{{ item.attributes.options[0].opening_type }}</p>
          </div>
        </div>

        <div
            v-if="item.attributes.options[0].fixing_type || ((!item.attributes.type.includes('venetian') && !item.attributes.type.includes('curtain') && !item.attributes.type.includes('vertical')) && item.attributes.options[0].recess_type)"
            class="half-section-container">
          <div
              v-if="((!item.attributes.type.includes('venetian') && !item.attributes.type.includes('curtain') && !item.attributes.type.includes('vertical')) && item.attributes.options[0].recess_type)"
              class="section-container">
            <p class="section-title">Recess Type</p>
            <p class="section-info">{{ item.attributes.options[0].recess_type }}</p>
          </div>

          <div v-if="item.attributes.options[0].fixing_type" class="section-container">
            <p class="section-title">Fixing Type</p>
            <p class="section-info">{{ $t(`calculators.${item.attributes.options[0].fixing_type}`) }}</p>
          </div>
        </div>

        <div v-if="item.attributes.options[0].finish || item.attributes.options[0].finish_style"
             class="half-section-container">
          <div v-if="item.attributes.options[0].finish" class="section-container">
            <p class="section-title">Finish</p>
            <p class="section-info">{{ item.attributes.options[0].finish }}</p>
          </div>
          <div v-if="item.attributes.options[0].finish_style" class="section-container">
            <p class="section-title">Finish Style</p>
            <p class="section-info">{{ item.attributes.options[0].finish_style }}</p>
          </div>
        </div>

        <div
            v-if="item.attributes.options[0].chain_side || item.attributes.options[0].chain_type ||  item.attributes.options[0].control_type || (item.attributes.options[0].controls_side && !item.attributes.type.includes('vertical')) || item.attributes.options[0].cord_side || item.attributes.options[0].wand_side"
            class="half-section-container">
          <div v-if="item.attributes.options[0].chain_side" class="section-container">
            <p class="section-title">Chain Side</p>
            <p class="section-info">{{ item.attributes.options[0].chain_side }}</p>
          </div>
          <div v-if="item.attributes.options[0].chain_type" class="section-container">
            <p class="section-title">Chain Type</p>
            <p class="section-info">{{ item.attributes.options[0].chain_type }}</p>
          </div>
          <div v-if="item.attributes.options[0].control_type" class="section-container">
            <p class="section-title">Control Type</p>
            <p class="section-info">{{ item.attributes.options[0].control_type }}</p>
          </div>
          <div v-if="item.attributes.options[0].controls_side && !item.attributes.type.includes('vertical')"
               class="section-container">
            <p class="section-title">Controls Side</p>
            <p class="section-info">{{ item.attributes.options[0].controls_side }}</p>
          </div>
          <div v-if="item.attributes.options[0].cord_side" class="section-container">
            <p class="section-title">Cord Side</p>
            <p class="section-info">{{ item.attributes.options[0].cord_side }}</p>
          </div>
          <div v-if="item.attributes.options[0].wand_side" class="section-container">
            <p class="section-title">Wand Side</p>
            <p class="section-info">{{ item.attributes.options[0].wand_side }}</p>
          </div>
        </div>


        <div v-if="item.attributes.options[0].opening_type && item.attributes.type.includes('curtain')"
             class="half-section-container">
          <div v-if="item.attributes.options[0].opening_type && item.attributes.type.includes('curtain')"
               class="section-container">
            <p class="section-title">Opening Type</p>
            <p class="section-info">{{ item.attributes.options[0].opening_type }}</p>
          </div>
        </div>


        <div
            v-if="item.attributes.options[0].tile_cut_width != null || (item.attributes.options[0].tile_cut_width_clearance && !item.attributes.type.includes('zebra') && !item.attributes.type.includes('roller'))"
            class="half-section-container">
          <div v-if="item.attributes.options[0].tile_cut_width != null" class="section-container">
            <p class="section-title">Tile Cut Width</p>
            <p v-if="!item.attributes.type.includes('zebra') && !item.attributes.type.includes('roller')"
               class="section-info">
              {{ item.attributes.options[0].tile_cut_width }} cm</p>
            <p v-else class="section-info">
              <span v-if="parseFloat(item.attributes.options[0].tile_cut_width) === 0">No</span>
              <span v-else-if="parseFloat(item.attributes.options[0].tile_cut_width) > 0">Compensated</span>
            </p>
          </div>
          <div
              v-if="item.attributes.options[0].tile_cut_width_clearance && !item.attributes.type.includes('zebra') && !item.attributes.type.includes('roller')"
              class="section-container">
            <p class="section-title">Tile Cut Width Clearance</p>
            <p class="section-info">{{ item.attributes.options[0].tile_cut_width_clearance }}</p>
          </div>
        </div>

        <div v-if="item.attributes.options[0].tile_cut_height" class="half-section-container">
          <div v-if="item.attributes.options[0].tile_cut_height" class="section-container">
            <p class="section-title">Tile Cut Height</p>
            <p class="section-info">{{ item.attributes.options[0].tile_cut_height }} cm</p>
          </div>
        </div>

        <div v-if="item.attributes.options[0].bottom_bar" class="half-section-container">
          <div v-if="item.attributes.options[0].bottom_bar" class="section-container">
            <p class="section-title">Bottom Bar</p>
            <p class="section-info">{{ item.attributes.options[0].bottom_bar }}</p>
          </div>
        </div>

        <div
            v-if="item.attributes.options[0].motor || item.attributes.options[0].controller_type || item.attributes.options[0].controller_types"
            class="half-section-container">
          <div v-if="item.attributes.options[0].motor" class="section-container">
            <p class="section-title">Motor</p>
            <p class="section-info">{{ item.attributes.options[0].motor }}</p>
          </div>

          <div v-if="item.attributes.options[0].controller_type" class="section-container">
            <p class="section-title">Controller Type</p>
            <p class="section-info">{{ item.attributes.options[0].controller_type }}</p>
          </div>

          <div v-if="item.attributes.options[0].controller_types" class="section-container">
            <p class="section-title">Controller Types</p>
            <p class="section-info">
              {{ item.attributes.options[0].controller_types.map(b => b.attributes.name).join(', ') }}</p>
          </div>
        </div>

        <div v-if="item.attributes.options[0].top_tube_fabric_roll " class="half-section-container">
          <div v-if="item.attributes.options[0].top_tube_fabric_roll" class="section-container">
            <p class="section-title">Fabric Roll</p>
            <p class="section-info">{{ item.attributes.options[0].top_tube_fabric_roll }}</p>
          </div>
        </div>

        <div
            v-if="item.attributes.options[0].fabric_type || item.attributes.options[0].fabric_colour || item.attributes.options[0].fabric_colour_options"
            class="half-section-container">
          <div v-if="item.attributes.options[0].fabric_type" class="section-container">
            <p class="section-title">Fabric Type</p>
            <p class="section-info">{{ item.attributes.options[0].fabric_type }}</p>
          </div>

          <div v-if="item.attributes.options[0].fabric_colour" class="section-container">
            <p class="section-title">Fabric Colour</p>
            <p class="section-info">{{ item.attributes.options[0].fabric_colour }}</p>
          </div>

          <div v-if="item.attributes.options[0].fabric_colour_options" class="section-container">
            <p class="section-title">Fabric Colour Options</p>
            <p class="section-info">{{ item.attributes.options[0].fabric_colour_options }}</p>
          </div>
        </div>

        <div v-for="colour in item.attributes.options[0].fabric_colours_selected"
             v-if="item.attributes.options[0].fabric_colours_selected.length"
             class="half-section-container">
          <div class="section-container">
            <p class="section-title">Fabric Colour</p>
            <p class="section-info">{{ colour.name }}</p>
          </div>

          <div
              v-if="item.attributes.options[0].fabric_colour_options == 'Two Colour' && colour.first_outer_slat_qty > 0"
              class="section-container">
            <p class="section-title">Outer Colour Slat Qty</p>
            <p class="section-info">{{ colour.first_outer_slat_qty }}R / {{ colour.first_outer_slat_qty }}L</p>
          </div>

          <div v-if="item.attributes.options[0].fabric_colour_options == 'Two Colour' && colour.middle_slat_qty > 0"
               class="section-container">
            <p class="section-title">Middle Colour Slat Qty</p>
            <p class="section-info">{{ colour.middle_slat_qty }}</p>
          </div>

          <div
              v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.first_outer_slat_qty > 0"
              class="section-container">
            <p class="section-title">First Outer Colour Slat Qty</p>
            <p class="section-info">{{ colour.first_outer_slat_qty }}R / {{ colour.first_outer_slat_qty }}L</p>
          </div>

          <div
              v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.second_outer_slat_qty > 0"
              class="section-container">
            <p class="section-title">Second Outer Colour Slat Qty</p>
            <p class="section-info">{{ colour.second_outer_slat_qty }}R / {{ colour.second_outer_slat_qty }}L</p>
          </div>

          <div v-if="item.attributes.options[0].fabric_colour_options == 'Three Colour' && colour.middle_slat_qty > 0"
               class="section-container">
            <p class="section-title">Middle Colour Slat Qty</p>
            <p class="section-info">{{ colour.middle_slat_qty }}</p>
          </div>
        </div>

        <div v-if="item.attributes.options[0].fabric_colour_zx|| item.attributes.options[0].fabric_colour_dt"
             class="half-section-container">
          <div v-if="item.attributes.options[0].fabric_colour_zx" class="section-container">
            <p class="section-title">Fabrics & Colours - ZX</p>
            <p class="section-info">{{ item.attributes.options[0].fabric_colour_zx }}</p>
          </div>

          <div v-if="item.attributes.options[0].fabric_colour_dt" class="section-container">
            <p class="section-title">Fabrics & Colours - DT</p>
            <p class="section-info">{{ item.attributes.options[0].fabric_colour_dt }}</p>
          </div>
        </div>

        <div v-if="item.attributes.options[0].custom_colour_code" class="half-section-container">
          <div class="section-container">
            <p class="section-title">Custom Colour Code</p>
            <p class="section-info">{{ item.attributes.options[0].custom_colour_code }}</p>
          </div>
        </div>

        <div v-if="item.attributes.note" class="divider"/>

        <div v-if="item.attributes.note" class="half-section-container">
          <div class="section-container note-field">
            <p class="section-title">Note</p>
            <p class="section-info">{{ item.attributes.note }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="measurements_to_print.length > 0" :class="{'error': item.attributes.is_unavailable}"
         class="item-card mt-4">
      <div class="card-title-container">
        <p class="card-title mb-2">{{ $t('blinds.material_cutting_measurements') }}</p>
      </div>
      <template v-for="printArray in measurements_to_print" v-if="measurements_to_print.length > 0">
        <div v-if="printArray.values.length"
             :class="[printArray.width === 'two' ? 'half-section-container' : 'multiple-section-container-three', 'no-dashed']">
          <div v-for="printItem in printArray.values" class="section-container">
            <p class="section-title">{{ printItem.title }}</p>
            <div class="flex flex-row">
              <p class="section-info">{{ printItem.value }} {{ printItem.unit }}</p>
              <div v-if="printItem.title === 'Slat Qty' || printItem.title === 'Runner Qty'" class="slat-qty-box"></div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/modal/ConfirmModal";
import _ from "lodash";
import {returnMeasurements, returnRollerBlindMeasurements} from "@/utils/MeasurementUtils";

export default {
  name: "ItemCardPrintingMeasurements",
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      measurements_to_print: [],
      is_deleting: false
    }
  },
  mounted() {
    this.measurements_to_print = returnMeasurements(this.item)
  },
}
</script>

<style lang="scss" scoped>

.slat-qty-box {
  @apply h-5 w-12 ml-2 bg-white border border-black rounded-sm;
  margin-top: 0.25rem;
}

.item-card {
  @apply w-full flex flex-col max-w-full border-1 border-black bg-grey-print rounded-md h-auto;

  &.error {
    @apply border-primary;
  }

  .card-title-container {
    @apply flex flex-row px-4 py-1 bg-black text-white justify-between;

    .card-title {
      @apply font-bold text-sm -mt-2;
    }

    .right {
      @apply flex flex-row;

      p {
        @apply my-auto -mt-2;
      }

      .box {
        @apply h-5 w-5 ml-4 bg-white my-auto rounded-sm;
      }
    }
  }

  .multiple-section-container-three {
    @apply flex flex-row px-4 pt-1 pb-4 border-b border-grey;

    &:last-of-type {
      @apply border-none;
    }

    &.no-dashed {
      &:last-of-type {
        @apply border-none;
      }
    }

    .section-container {
      width: 33%;

      &:first-of-type {
        @apply mr-2;
      }

      &.note-field {
        @apply w-full;
      }

      .section-title {
        @apply text-xs font-bold mb-1;
      }

      .section-info {
        @apply text-xs break-normal;
        word-break: break-word;
      }

      .row {
        @apply flex flex-row;

        .box {
          @apply h-5 w-12 ml-2 bg-white border border-black rounded-sm;
          margin-top: 0.4rem;
        }
      }
    }
  }

  .half-section-container {
    @apply flex flex-row flex-wrap gap-y-4 px-4 pt-1 pb-4 border-b border-grey;


    &:last-of-type {
      @apply border-none;
    }

    &.no-dashed {
      &:last-of-type {
        @apply border-none;
      }
    }

    .section-container {
      width: 50%;

      .section-title {
        @apply text-xs font-bold mb-1;
      }

      .section-info {
        @apply text-xs break-normal;
        word-break: break-word;
      }

      .row {
        @apply flex flex-row;

        .box {
          @apply h-5 w-12 ml-2 bg-white border border-black rounded-sm;
          margin-top: 0.4rem;
        }
      }
    }
  }

  .multiple-section-container {
    @apply flex flex-row px-4 pt-1 pb-4 border-b border-grey;

    &:last-of-type {
      @apply border-none;
    }

    &.no-dashed {
      &:last-of-type {
        @apply border-none;
      }
    }

    .section-container {
      &:first-of-type {
        @apply mr-2;
        width: 40%;
      }

      &:last-of-type {
        width: 60%;
      }

      &.note-field {
        @apply w-full;
      }

      .section-title {
        @apply text-xs font-bold mb-1;
      }

      .section-info {
        @apply text-xs break-normal;
        word-break: break-word;
      }

      .row {
        @apply flex flex-row;

        .box {
          @apply h-5 w-12 ml-2 bg-white border border-black rounded-sm;
          margin-top: 0.4rem;
        }
      }
    }
  }

  .multiple-section-container-wrap {
    @apply flex flex-row flex-wrap px-4 pt-1 pb-4 gap-y-2 border-b border-grey;

    &:last-of-type {
      @apply border-none;
    }

    &.no-dashed {
      &:last-of-type {
        @apply border-none;
      }
    }

    .section-container {

      &:nth-of-type(odd) {
        width: 40%;
      }

      &:nth-of-type(even) {
        @apply pl-1;
        width: 60%;
      }

      &.note-field {
        @apply w-full;
      }

      .section-title {
        @apply text-xs font-bold mb-1;
      }

      .section-info {
        @apply text-xs break-normal;
        word-break: break-word;
      }

      .row {
        @apply flex flex-row;

        .box {
          @apply h-5 w-12 ml-2 bg-white border border-black rounded-sm;
          margin-top: 0.4rem;
        }
      }
    }
  }
}
</style>